<template>
  <div>
    <Menu mode="horizontal" :active-name="currentPath" v-show="roleCode!==4">
      <MenuItem to="./teachingcategories" v-if="roleCode ===6" name="teachingcategories">教材分类</MenuItem>
      <MenuItem to="./waitdeal" v-if="roleCode !==0&&roleCode !==4" name="waitdeal">待处理</MenuItem>
      <MenuItem to="./submitted" v-if="roleCode !==6&&roleCode !==4" name="submitted">已提交</MenuItem>
      <MenuItem to="./published" name="published">已发布</MenuItem>
      <MenuItem to="./sortPublished" v-if="roleCode ===6" name="sortPublished">已发布排序</MenuItem>
      <MenuItem to="./recycle" v-if="roleCode ===0" name="recycle">回收站</MenuItem>
    </Menu>
    <router-view />
  </div>
</template>

<script >
export default {
  computed: {
    roleCode() {
      return this.$store.getters.getRoleCode;
    },
    currentPath() {
      return this.$route.path.replace(/.*\/(\w*)$/, '$1');
    }
  },
  mounted() {}
};
</script>

<style scoped >
::v-deep .ivu-menu-horizontal {
  display: flex;
  justify-content: center;
  height: 36px;
  line-height: 36px;
  margin-bottom: 16px;
  z-index: 1;
}
</style>
